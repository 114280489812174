body {
    font-weight: 200;
    background-color: #337ab7;
    overflow: hidden;
}
  
  header a,
  footer a {
    color: white;
  }
  
  footer {
    font-size: 0.8em;
  }
  
  .bg-primary,
  .btn-primary {
    background-color: #377bb5 !important;
    border: inherit;
  }